import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase";
import axios from "axios";
import environment from "../../env.js";
import db from "../../db.js";
import * as editor from "./modules/editor";
import * as idle from "./modules/idle";
import * as global from "./modules/global";
import * as builderMain from "./modules/builder-main";
import * as annotation from "./modules/annotation";
import * as navigator from "./modules/navigator";
import * as navigatorSkill from "./modules/navigator-skill";
import * as category from "./modules/category";
import * as workflowComments from "./modules/workflow-comments";
import * as workflowPlayer from "./modules/workflow-player.js";
import * as workflowQuiz from "./modules/workflow-quiz.js";
import * as workflowReactions from "./modules/workflow-reactions";
import * as permission from "./modules/permission-setting";
import * as notification from "./modules/notification";
import * as textToSpeech from "./modules/text-to-speech";
import * as mavenChat from "./modules/maven-chat";
import * as mavenMap from "./modules/maven-map";
import * as workspaceGroups from "./modules/workspace-groups.js";
import * as workspace from "./modules/workspace";
import * as workspaceStates from "./modules/workspace-states";
import * as studio from "./modules/studio";
import * as auth from "./modules/auth";
import * as stepSentences from "./modules/step-sentences";
import * as aiQuizEditor from "./modules/aiQuizEditor";
import * as userConsents from "./modules/user-consents.js";
import * as maven2 from "./modules/maven2.js";
import * as rusticiProcess from "./modules/rustici-process.js";
import * as skillBuilder from "./modules/skill-builder.js";
import { store as skillNotification } from "@/store/modules/skill-notification.js";
import { store as workspaceColor } from "@/store/modules/workspace-color.js";
import { getUserProfileApi } from "@/server/api-server.js";
import { IS_MAVEN_WHITELIST_DOMAIN } from "@/constants/envVersion";

Vue.use(Vuex);

import { getBaseDataForUserDataTable, USER_DATA_TABLE_TYPE } from "@/js/user-data-table/user-data-table.js";
import userGuide from "@/js/userGuide/index.js";

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  workflows: [],
  userProfile: [],
  lastDoc: null,
  loadingWorkflow: false,
  googleUrlBridge: !!Vue.prototype.$firebaseProxyHost,
  previousQuery: null, //track the previous query; remove listener when a new query is created
  userLikes: [],
  emptyUserLikes: false,
  users: [], //all users in the org
  lastUserDoc: null,
  playlists: [],
  lastPlaylistDoc: null,
  previousPlaylistQuery: null,
  getWorkflowsCounter: 0,
  lastInvitationDoc: null,
  invitations: [], //all invitations in the org
  groupUsers: [], //the users in the selected group in admin console
  groupInactiveUsers: [],
  lastGroupUserDoc: null,
  lastGroupInactiveUserDoc: null,
  groupInactiveUserStore: {},
  groupUserStore: {},
  groupInvitations: [], //the invitations in the selected group in admin console
  lastGroupInvitationDoc: null,
  groupInvitationStore: {},
  inactiveUsers: [],
  lastInactiveUserDoc: null,
  loadingUsers: false,
  loadingInactiveUsers: false,
  loadingInvitations: false,
  loadingGroupUsers: false,
  loadingGroupInactiveUsers: false,
  loadingGroupInvitations: false,
  previousWorkflowGroupId: null,
  visitedEmptyGroup: false,
  //uploader
  isRecordingScreen: false,
  //categories
  categoriesData: [],
  lastCatgoryDoc: null,
  loadingCategories: false,
};
const batchSize = 1500;
const stringConstructor = "testConstructor".constructor;
const arrayConstructor = [].constructor;
const objectConstructor = {}.constructor;
const proxyTargetReplaceStr = "googleapis.com";

const docGoogleUrlBridgeReplacementObjKeyStringFn = function (state, obj, objKey) {
  // https://dhteam.atlassian.net/jira/software/projects/TE/boards/10?selectedIssue=TE-13

  if (!!obj[objKey] && obj[objKey].constructor === stringConstructor && obj[objKey].charAt(0) === "h") {
    // precheck for speed up

    if (obj[objKey].includes(proxyTargetReplaceStr)) {
      obj[objKey] = obj[objKey].replace(proxyTargetReplaceStr, Vue.prototype.$firebaseProxyHost);
    }
  }
};

const docGoogleUrlBridgeReplacementFn = function (state, doc) {
  // might having poor perfomance. but just poc
  // TODO Dev cn nginx POC
  if (!state.googleUrlBridge) {
    return;
  }
  const depthHandle = [];
  for (const docKey in doc) {
    if (!doc[docKey]) {
      continue;
    }
    if (doc[docKey].constructor === arrayConstructor) {
      for (const nextDoc of doc[docKey]) {
        depthHandle.push(nextDoc);
      }
    } else if (doc[docKey].constructor === objectConstructor) {
      depthHandle.push(doc[docKey]);
    } else {
      docGoogleUrlBridgeReplacementObjKeyStringFn(state, doc, docKey);
    }
  }
  for (const nextRoundDoc of depthHandle) {
    docGoogleUrlBridgeReplacementFn(state, nextRoundDoc);
  }
};

const getGoogleFirestore = function () {
  // we don't use firestore in ali
  return environment.backendType != "ali" ? firebase.firestore() : null;
};

const getEmployeeIdFormat = function (user) {
  const re = /\.(.*?)\@/;
  let id = user.email.includes("@deephow.ai") && re.exec(user.email) ? re.exec(user.email)[1] : "";
  id = user.shared && id.substring(0, 7) === "shared." ? id.slice(7) : id;
  return id;
};
const getNewUserFormat = function (user, userDataTableType) {
  const transform = {
    employeeId: user.employeeId ? user.employeeId : getEmployeeIdFormat(user),
    ...getBaseDataForUserDataTable({
      user,
      dataTableType: userDataTableType,
    }),
  };
  return { ...user, ...transform };
};

const getGroupUserFormat = function (user, group, userDataTableType) {
  let currentRole;
  if (user.roles && user.roles[group]) {
    currentRole = user.roles[group];
  } else {
    currentRole = user.role;
  }

  if (user.employeeId) {
    //for employee id user
    return {
      ...user,
      ...getBaseDataForUserDataTable({
        user,
        dataTableType: userDataTableType,
      }),
    };
  } else {
    const transform = {
      currentRole: currentRole,
      employeeId: getEmployeeIdFormat(user),
      status: user.disabled === true ? "inactive" : "active",
    };
    return {
      ...user,
      ...transform,
      ...getBaseDataForUserDataTable({
        user,
        dataTableType: userDataTableType,
      }),
    };
  }
};

const handleGetGroupWorkflows = function (commit, { group, privateWorkspace = false }, comparisons) {
  if (state.previousWorkflowGroupId && group !== state.previousWorkflowGroupId) {
    commit("clearWorkflowsRef");
  }
  state.previousWorkflowGroupId = group;
  comparisons.push({
    field: "group",
    comparison: "==",
    value: group,
  });
  comparisons.push({
    field: "privateWorkspace",
    comparison: "==",
    value: privateWorkspace,
  });
};

const handleGetSubCatWorkflows = function (args, comparisons) {
  if (args.subCategories.operator == "AND") {
    args.subCategories.list.forEach((subCat) => {
      comparisons.push({
        field: `subCatMap.${subCat}`,
        comparison: "==",
        value: true,
      });
    });
  } else if (args.subCategories.operator == "OR") {
    comparisons.push({
      field: "subCategories",
      comparison: "array-contains-any",
      value: args.subCategories.list,
    });
  }
};

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
  /////////// PLAYLISTS //////////
  addPlaylists(state, { doc }) {
    const playlist = doc.data();
    state.playlists.push(playlist);
    state.lastPlaylistDoc = doc;
  },
  removePlaylists(state, { doc }) {
    const playlist = doc.data();
    state.playlists = state.playlists.filter((el) => {
      return el.id != playlist.id;
    });
  },
  clearPlaylists(state) {
    // debugger
    state.playlists.length = 0;
    state.lastPlaylistDoc = null;
  },
  //empty functions for event subscribing
  getPlaylistsEnd(state) {},
  getMorePlaylistsEnd(state) {},
  getUsersEnd(state) {
    state.loadingUsers = false;
    console.log("loading users from store ended");
  },
  getInactiveUsersEnd(state) {
    state.loadingInactiveUsers = false;
    console.log("loading inactive users from store ended");
  },
  getInvitationsEnd(state) {
    state.loadingInvitations = false;
    console.log("loading invitations from store ended");
  },
  getGroupUsersEnd(state) {
    state.loadingGroupUsers = false;
    console.log("loading group users from store ended");
  },
  getGroupInativeUsersEnd(state) {
    state.loadingGroupInactiveUsers = false;
    console.log("loading group inactive users from store ended");
  },
  getGroupInvitationsEnd(state) {
    state.loadingGroupInvitations = false;
    console.log("loading group invitations from store ended");
  },
  removeOneWorkflow(state, { change }) {
    const workflow = change.doc.data();

    if (!!state.googleUrlBridge) {
      // TODO Dev cn nginx POC
      docGoogleUrlBridgeReplacementFn(state, workflow);
      // console.log(workflow);
    }
    for (let i = 0; i < state.workflows.length; i++) {
      if (state.workflows[i].id == workflow.id) {
        state.workflows.splice(i, 1);
      }
    }
  },
  updateOneWorkflow(state, { change }) {
    const workflow = change.doc.data();
    if (!!state.googleUrlBridge) {
      // TODO Dev cn nginx POC
      docGoogleUrlBridgeReplacementFn(state, workflow);
      // console.log(workflow);
    }
    for (let i = 0; i < state.workflows.length; i++) {
      if (state.workflows[i].id == workflow.id) {
        state.workflows.splice(i, 1, workflow);
      }
    }
    // state.workflows.forEach(el=>{
    //   if(el.id == workflow.id){
    //     el = workflow;
    //     alert("hi")
    //   }
    // })
    // state.workflows.splice();
  },
  addOneWorkflow(state, { change }) {
    const workflow = change.doc.data();
    if (!!state.googleUrlBridge) {
      // TODO Dev cn nginx POC
      docGoogleUrlBridgeReplacementFn(state, workflow);
      // console.log(workflow);
    }
    //prevent duplicated
    for (let i = 0; i < state.workflows.length; i++) {
      if (state.workflows[i].id == workflow.id) {
        state.workflows.splice(i, 1);
      }
    }
    state.workflows.push(workflow);
    state.lastDoc = change.doc;
  },
  addWorkflows(state, { doc }) {
    if (doc.data().id != doc.id) {
      alert("document:" + doc.id + " is corrupted.");
    }
    const workflow = doc.data();

    if (!!state.googleUrlBridge) {
      // TODO Dev cn nginx POC
      docGoogleUrlBridgeReplacementFn(state, workflow);
      // console.log(workflow);
    }

    state.workflows.push(workflow);
    state.lastDoc = doc;
  },
  removeWorkflows(state, { doc }) {
    const workflow = doc.data();
    state.workflows = state.workflows.filter((el) => {
      return el.id != workflow.id;
    });
  },
  clearWorkflows(state) {
    // debugger
    state.workflows.splice(0);
    state.lastDoc = null;
  },
  clearWorkflowsRef(
    state,
    { isClearWorkflowArray } = {
      isClearWorkflowArray: true,
    }
  ) {
    if (isClearWorkflowArray) {
      state.workflows.splice(0);
    }
    state.lastDoc = null;
    state.previousWorkflowGroupId = null;
    state.previousQuery();
  },
  setUserProfile(state, { data }) {
    /** TODO: move user profile state, mutation and action to user module */
    state.userProfile.push(data);
  },
  docGoogleUrlBridgeReplacement(state, { doc }) {
    docGoogleUrlBridgeReplacementFn(state, doc);
  },
  setUserLikes(state, { data, like }) {
    if (like === false) {
      const index = state.userLikes.indexOf(data[0]);
      state.userLikes.splice(index, 1);
    } else {
      //prevent duplicated
      for (let i = 0; i < data.length; i++) {
        if (!state.userLikes.includes(data[i])) {
          state.userLikes.push(data[i]);
        }
      }
      //state.userLikes.push(...data);
    }
  },
  getUserLikesEnd(state) {
    console.log("loading userLikes from store ended");
  },
  addUsers(state, { doc }) {
    //THIS INCLUDES ONLY ACTIVE USERS
    const user = doc.data();
    user["uid"] = user.id;
    if (!user.disabled) {
      state.users.push(getNewUserFormat(user, USER_DATA_TABLE_TYPE.ACTIVE));
    }
    state.lastUserDoc = doc;
  },
  addInactiveUsers(state, { doc }) {
    const user = doc.data();
    user["uid"] = user.id;
    state.inactiveUsers.push(getNewUserFormat(user, USER_DATA_TABLE_TYPE.INACTIVE));
    state.lastInactiveUserDoc = doc;
  },
  addGroupUsers(state, { doc, group }) {
    const user = doc.data();
    user["uid"] = user.id;
    if (!user.disabled) {
      const newGroupUser = getGroupUserFormat(user, group, USER_DATA_TABLE_TYPE.ACTIVE);
      const duplicatedDataIndex = state.groupUsers.findIndex((groupUser) => groupUser.id === newGroupUser.id);
      const hasDuplicatedData = duplicatedDataIndex > -1;
      if (hasDuplicatedData) {
        state.groupUsers.splice(duplicatedDataIndex, 1, newGroupUser);
      } else {
        state.groupUsers.push(newGroupUser);
      }
    }
    state.lastGroupUserDoc = doc;
  },
  addGroupInactiveUsers(state, { doc, group }) {
    const user = doc.data();
    if (!state.groupInactiveUsers.some(({ id }) => id === user.id)) {
      user["uid"] = user.id;
      state.groupInactiveUsers.push(getGroupUserFormat(user, group, USER_DATA_TABLE_TYPE.INACTIVE));
    }
    state.lastGroupInactiveUserDoc = doc;
  },
  clearGroupUserCacheByUserID(state, { userId }) {
    if (!state.groupUserStore) {
      return;
    }
    Object.entries(state.groupUserStore).forEach(([groupId, cachedValue]) => {
      if (!cachedValue || !Array.isArray(cachedValue.groupUsers)) {
        return;
      }
      const hasTargetUser = !!cachedValue.groupUsers.find((groupUser) => groupUser.id === userId);
      if (hasTargetUser) {
        delete state.groupUserStore[groupId];
      }
    });
  },
  cacheAndClearGroupUsers(state) {
    //cache the old and then clear the current reference
    state.groupUserStore[state.currentGroup] = {
      groupUsers: Array.from(state.groupUsers),
      lastGroupUserDoc: state.lastGroupUserDoc,
    };
    state.groupUsers.length = 0;
    // state.groupUsers.splice(0,);
    state.lastGroupUserDoc = null;
    state.currentGroup = null;
  },
  cacheAndClearGroupInactiveUsers(state) {
    //cache the old and then clear the current reference
    state.groupInactiveUserStore[state.currentInactiveGroup] = {
      groupInactiveUsers: Array.from(state.groupInactiveUsers),
      lastGroupInactiveUserDoc: state.lastGroupInactiveUserDoc,
    };
    state.groupInactiveUsers.length = 0;
    // state.groupUsers.splice(0,);
    state.lastGroupInactiveUserDoc = null;
    //state.currentGroup = null;
  },
  addInvitation(state, { doc }) {
    const user = doc.data();
    if (user.status != "finished") {
      state.invitations.push({
        email: user.email,
        id: user.id,
        displayName: user.displayName,
        role: user.role,
        invitation: true,
        ...getBaseDataForUserDataTable({
          user,
          dataTableType: USER_DATA_TABLE_TYPE.INVITED,
        }),
      });
    }
    state.lastInvitationDoc = doc;
  },
  addGroupInvitation(state, { doc }) {
    const user = doc.data();
    if (user.status != "finished") {
      state.groupInvitations.push({
        email: user.email,
        id: user.id,
        displayName: user.displayName,
        role: user.role,
        invitation: true,
        ...getBaseDataForUserDataTable({
          user,
          dataTableType: USER_DATA_TABLE_TYPE.INVITED,
        }),
      });
    }
    state.lastGroupInvitationDoc = doc;
  },
  cacheAndClearGroupInvitations(state) {
    //cache the old and then clear the current reference
    state.groupInvitationStore[state.currentInvitationGroup] = {
      groupInvitations: Array.from(state.groupInvitations),
      lastGroupInvitationDoc: state.lastGroupInvitationDoc,
    };
    state.groupInvitations.length = 0;
    state.lastGroupInvitationDoc = null;
    state.currentInvitationGroup = null;
  },
  addNewUser(state, { user, level }) {
    //add user to group level
    if (level == "group") {
      //check user status
      if (user.disabled) {
        state.groupInactiveUsers.unshift(getGroupUserFormat(user, null, USER_DATA_TABLE_TYPE.INACTIVE));
      } else {
        state.groupUsers.unshift(getGroupUserFormat(user, null, USER_DATA_TABLE_TYPE.ACTIVE));
      }
    } else if (level == "org") {
      //add user to org level
      state.users.unshift(getNewUserFormat(user, USER_DATA_TABLE_TYPE.ACTIVE));
    }
  },
  addNewInvitation(state, { invite, level }) {
    invite.invitation = true;
    //add user to org level
    state.invitations.unshift(invite);
    //add user to group level
    if (level == "group") {
      state.groupInvitations.unshift(invite);
    }
    //add user to cache
    Object.keys(state.groupInvitationStore).forEach((key) => {
      if (key == invite.groups[0]) {
        state.groupInvitationStore[key].unshift(invite);
      }
    });
  },
  updateInvitation(state, { invite, action }) {
    //remove/update org level
    for (let i = 0; i < state.invitations.length; i++) {
      if (state.invitations[i].id == invite.id) {
        if (action == "remove") {
          state.invitations.splice(i, 1);
        } else if (action == "update") {
          state.invitations.splice(i, 1, invite);
        }
      }
    }
    //remove/update group level
    for (let i = 0; i < state.groupInvitations.length; i++) {
      if (state.groupInvitations[i].id == invite.id) {
        if (action == "remove") {
          state.groupInvitations.splice(i, 1);
        } else if (action == "update") {
          state.groupInvitations.splice(i, 1, invite);
        }
      }
    }
    //remove/update cache
    Object.keys(state.groupInvitationStore).forEach((key) => {
      for (let i = 0; i < state.groupInvitationStore[key].length; i++) {
        if (state.groupInvitationStore[key][i].id == invite.id) {
          if (action == "remove") {
            state.groupInvitationStore[key].splice(i, 1);
          } else if (action == "update") {
            state.groupInvitationStore[key].splice(i, 1, invite);
          }
        }
      }
    });
  },
  updateUserWithoutGroup(state, { user, update }) {
    //update org users
    for (let i = 0; i < state.users.length; i++) {
      if (state.users[i].id == user.id) {
        const data = { ...user, ...update };
        if (update.disabled === true) {
          state.users.splice(i, 1); //remove it from active users
          state.inactiveUsers.splice(0, 0, data); //add it to inactive users
        } else {
          //update displayName
          state.users.splice(i, 1, data);
        }
      }
    }
    //update org inactive users
    for (let i = 0; i < state.inactiveUsers.length; i++) {
      if (state.inactiveUsers[i].id == user.id) {
        const data = { ...user, ...update };
        if (update.disabled === false) {
          state.inactiveUsers.splice(i, 1); //remove it from inactive users
          state.users.splice(0, 0, data); //add it to active users
        } else {
          //update displayName
          state.inactiveUsers.splice(i, 1, data);
        }
      }
    }
  },
  updateUser(state, { user, update, action }) {
    this.commit("updateUserWithoutGroup", {
      user,
      update,
    });

    if (action === "update") {
      const data = { ...user, ...update };
      userGuide.updateUserInfo(data.id, {
        organizationId: data.organization,
        highestRole: data.role,
        uiLanguage: data.locale,
        dateCreated: data.createdAt,
        disabled: data.disabled,
      });
    }

    //update groupUsers
    for (let i = 0; i < state.groupUsers.length; i++) {
      if (state.groupUsers[i].id == user.id) {
        if (action == "remove") {
          state.groupUsers.splice(i, 1);
          return;
        }
        const data = { ...user, ...update };
        if (update.disabled === true) {
          state.groupUsers.splice(i, 1);
          state.groupInactiveUsers.splice(0, 0, data);
        } else {
          // update user data
          state.groupUsers.splice(i, 1, data);
        }
      }
    }
    //update group inactive users
    for (let i = 0; i < state.groupInactiveUsers.length; i++) {
      if (state.groupInactiveUsers[i].id == user.id) {
        if (action == "remove") {
          state.groupInactiveUsers.splice(i, 1);
          return;
        }
        const data = { ...user, ...update };
        if (update.disabled === false) {
          state.groupInactiveUsers.splice(i, 1); //remove it from inactive users
          state.groupUsers.splice(0, 0, data); //add it to active users
        } else {
          // update user data
          state.groupInactiveUsers.splice(i, 1, data);
        }
      }
    }
    //update user in groupUserStore
    Object.keys(state.groupUserStore).forEach((group) => {
      const groupUsersData = state.groupUserStore[group] ? state.groupUserStore[group] : [];
      const groupInactiveUsers = state.groupInactiveUserStore[group] ? state.groupInactiveUserStore[group] : [];
      //update active
      for (let i = 0; i < groupUsersData.groupUsers.length; i++) {
        if (groupUsersData.groupUsers[i].id == user.id) {
          const data = { ...user, ...update };
          if (update.disabled === true) {
            groupUsersData.groupUsers.splice(i, 1);
            groupInactiveUsers.splice(0, 0, data);
          } else {
            // update user data
            groupUsersData.groupUsers.splice(i, 1, data);
          }
        }
      }
      //update inactive
      for (let i = 0; i < groupInactiveUsers.length; i++) {
        if (groupInactiveUsers[i].id == user.id) {
          const data = { ...user, ...update };
          if (update.disabled === false) {
            groupInactiveUsers.splice(i, 1);
            groupUsers.splice(0, 0, data);
          } else {
            //update displayName
            groupInactiveUsers.splice(i, 1, data);
          }
        }
      }
    });
  },
  //uploader
  updateIsRecording(state, booType) {
    state.isRecordingScreen = booType;
  },
  ///////Categories//////
  addCategories(state, { doc, items }) {
    state.lastCatgoryDoc = doc;
    let cat = doc.data();
    cat.items = items;
    state.categoriesData.push(cat);
  },
  clearCategories(state) {
    state.categoriesData.length = 0;
    state.lastCatgoryDoc = null;
  },
  getCategoriesEnd(state) {
    state.loadingCategories = false;
    console.log("loading categories from store ended");
  },
  updateCategory(state, { category, update }) {
    for (let i = 0; i < state.categoriesData.length; i++) {
      if (state.categoriesData[i].id == category.id) {
        const data = { ...category, ...update };
        state.categoriesData.splice(i, 1, data);
      }
    }
  },
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  getMorePlaylists({ commit }, args) {
    this.$db = getGoogleFirestore();
    //avoid repeated operations
    if (state.loadingWorkflow || !state.lastPlaylistDoc) {
      return;
    }
    // debugger
    if (!args.quantity) {
      args.quantity = 100;
    }
    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
    ];
    if (args.group) {
      comparisons.push({
        field: "group",
        comparison: "==",
        value: args.group,
      });
    }
    state.loadingWorkflow = true;
    setTimeout(() => {
      state.loadingWorkflow = false;
    }, 3000);
    console.log("getting " + args.quantity + " more playlists from store");
    //TODO: stop using query listener for getmoreworkflows, this could result in having more listeners than necessary and waste resources
    db.getDocumentQueryListener.call(
      this,
      "playlists",
      comparisons,
      [
        {
          type: "orderBy",
          value: "'publishedDate', 'desc'",
        },
        {
          type: "startAfter",
          value: state.lastPlaylistDoc,
        },
        {
          type: "limit",
          value: args.quantity,
        },
      ],
      function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          state.loadingWorkflow = false;
          querySnapshot.forEach(function (doc) {
            commit("removePlaylists", {
              doc,
            });
            commit("addPlaylists", {
              doc,
            });
          });
        });
        commit("getMorePlaylistsEnd");
      }
    );
  },
  getPlaylists({ commit }, args) {
    this.$db = getGoogleFirestore(); //must do this to pass $db into getDocumentQueryListener
    console.log("loading playlists from store");
    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
    ];
    if (args.group) {
      comparisons.push({
        field: "group",
        comparison: "==",
        value: args.group,
      });
    }
    // debugger
    const query = db.getDocumentQueryListener.call(
      this,
      "playlists",
      comparisons,
      [
        {
          type: "orderBy",
          value: "'publishedDate', 'desc'",
        },
        {
          type: "limit",
          value: batchSize,
        },
      ],
      function (querySnapshot) {
        commit("clearPlaylists"); //clear workflows with every update
        querySnapshot.forEach(function (doc) {
          commit("addPlaylists", {
            doc,
          });
        });
        commit("getPlaylistsEnd");
      }
    );
    // unlisten previous query
    if (state.previousPlaylistQuery) {
      // debugger
      try {
        state.previousPlaylistQuery();
      } catch (err) {
        // debugger
      }
    }
    // set new query to previousPlaylistQuery
    state.previousPlaylistQuery = query;
  },
  //getMoreWorkflows is called when more workflows need to be downloaded
  getMoreWorkflows({ commit }, args) {
    this.$db = getGoogleFirestore();
    //avoid repeated operations
    if (state.loadingWorkflow || (!state.lastDoc && !state.visitedEmptyGroup)) {
      return;
    }
    state.loadingWorkflow = true;

    // debugger
    if (!args.quantity) {
      args.quantity = 100;
    }
    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
    ];
    if (args.group) {
      handleGetGroupWorkflows(commit, args, comparisons);
    } else {
      comparisons.push({
        field: "privateWorkspace",
        comparison: "==",
        value: false,
      });

      //move from group-level data to org-level data
      if (state.previousWorkflowGroupId) {
        commit("clearWorkflowsRef");
      }
    }

    if (args.subCategories) {
      handleGetSubCatWorkflows(args, comparisons);
    }

    setTimeout(() => {
      state.loadingWorkflow = false;
    }, 3000);
    console.log("getting " + args.quantity + " more workflows from store");
    const methods = [
      {
        type: "orderBy",
        value: "'publishedDate', 'desc'",
      },
      {
        type: "limit",
        value: args.quantity,
      },
    ];

    if (state.lastDoc) {
      methods.push({
        type: "startAfter",
        value: state.lastDoc,
      });
    }
    //TODO: stop using query listener for getmoreworkflows, this could result in having more listeners than necessary and waste resources
    db.getDocumentQueryListener.call(this, "workflows", comparisons, methods, function (querySnapshot) {
      state.loadingWorkflow = false;
      if (environment.backendType != "ali") {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            // console.log("add")
            commit("addOneWorkflow", {
              change,
            });
          }
          if (change.type === "modified") {
            commit("updateOneWorkflow", {
              change,
            });
          }
          if (change.type === "removed") {
            commit("removeOneWorkflow", {
              change,
            });
          }
        });
        //no query results
        if (!state.lastDoc) {
          state.visitedEmptyGroup = true;
        } else {
          state.visitedEmptyGroup = false;
        }
      } else {
        querySnapshot.forEach(function (doc) {
          commit("removeWorkflows", {
            doc,
          });
          commit("addWorkflows", {
            doc,
          });
        });
      }
    });
    // firebase.firestore().collection("workflows")
    //   .where("organization", "==", args.organization)
    //   .orderBy("publishedDate", "desc")
    //   .startAfter(state.lastDoc)
    //   .limit(args.quantity)
    //   .onSnapshot(function (querySnapshot) {
    //     debugger
    //     state.loadingWorkflow = false;
    //     querySnapshot.forEach(function (doc) {
    //       commit("removeWorkflows", {
    //         doc
    //       });
    //       commit("addWorkflows", {
    //         doc
    //       });
    //     });
    //   });
  },
  //getWorkflows is called when the app first start
  getWorkflows({ commit }, args) {
    const isClearWorkflowArray = !args.isStopClearWorkflowArray;
    if (environment.backendType != "ali") {
      //avoid repeated getWorkflows except for subCategories & getWorkflowsAgain
      if (state.getWorkflowsCounter > 0 && !args.subCategories && !args.getWorkflowsAgain) {
        return;
      }
      //use this when we need to manually run getWorkflows again
      if (args.getWorkflowsAgain) {
        commit("clearWorkflowsRef", {
          isClearWorkflowArray,
        });
      }

      state.getWorkflowsCounter++;
    }
    this.$db = getGoogleFirestore(); //must do this to pass $db into getDocumentQueryListener
    console.log("loading workflows from store");
    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
    ];
    if (args.group) {
      handleGetGroupWorkflows(commit, args, comparisons);
    } else {
      comparisons.push({
        field: "privateWorkspace",
        comparison: "==",
        value: false,
      });

      //move from group-level data to org-level data
      if (state.previousWorkflowGroupId) {
        commit("clearWorkflowsRef", {
          isClearWorkflowArray,
        });
      }
    }

    if (args.subCategories) {
      commit("clearWorkflowsRef", {
        isClearWorkflowArray,
      });
      handleGetSubCatWorkflows(args, comparisons);
    }
    // debugger
    const query = db.getDocumentQueryListener.call(
      this,
      "workflows",
      comparisons,
      [
        {
          type: "orderBy",
          value: "'publishedDate', 'desc'",
        },
        {
          type: "limit",
          value: batchSize,
        },
      ],
      function (querySnapshot) {
        if (environment.backendType != "ali") {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              commit("addOneWorkflow", {
                change,
              });
            }
            if (change.type === "modified") {
              commit("updateOneWorkflow", {
                change,
              });
            }
            if (change.type === "removed") {
              commit("removeOneWorkflow", {
                change,
              });
            }
          });
        } else {
          commit("clearWorkflows"); //clear workflows with every update
          querySnapshot.forEach(function (doc) {
            // debugger
            commit("addWorkflows", {
              doc,
            });
          });
        }
      }
    );
    // unlisten previous query
    if (state.previousQuery) {
      // debugger
      try {
        state.previousQuery();
      } catch (err) {
        // debugger
      }
    }
    // set new query to previousQuery
    state.previousQuery = query;
  },
  getUserProfile({ commit }) {
    if (state.userProfile.length > 0) {
      return;
    }
    return getUserProfileApi().then(async (res) => {
      const { ok, data } = res;
      if (ok) {
        data.item.changeCount = 0;
        commit("setUserProfile", {
          data: data.item,
        });
      }
    });
  },
  updateUserProfile({ state }, { index, data }) {
    /** TODO: move user profile state, mutation and action to user module */
    if (!state.userProfile[index]) return;
    const newUserProfile = {
      ...state.userProfile[index],
      ...data,
    };
    const cloneDeepData = state.userProfile.map((ele, eleIdx) => {
      return eleIdx === index ? newUserProfile : ele;
    });
    state.userProfile = cloneDeepData;
  },
  docGoogleUrlBridgeReplacement({ commit }, doc) {
    commit("docGoogleUrlBridgeReplacement", { doc });
  },
  getUserLikes({ commit }, user) {
    if (!user) {
      console.log("user not available for likes.");
      commit("getUserLikesEnd");
      return;
    }
    if (state.emptyUserLikes || state.userLikes.length > 0) {
      commit("getUserLikesEnd");
      return;
    }
    this.$db = getGoogleFirestore();
    db.getDocument
      .call(this, "userLikes", user.uid)
      .then((likes) => {
        const data = likes.workflows;
        commit("setUserLikes", {
          data,
        });
        if (data.length == 0) {
          state.emptyUserLikes = true;
        }
      })
      .then(() => {
        commit("getUserLikesEnd");
      })
      .catch((err) => {
        //console.log(err)
        commit("getUserLikesEnd");
      });
  },
  getUsers({ commit }, args) {
    if (state.loadingUsers) {
      return;
    }
    state.loadingUsers = true;
    setTimeout(() => {
      commit("getUsersEnd");
    }, 5000);

    this.$db = getGoogleFirestore(); //must do this to pass $db into db call
    console.log("loading users from store");
    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
    ];
    let methods = [
      {
        type: "limit",
        value: 100,
      },
      {
        type: "orderBy",
        value: "'displayName'",
      },
    ];
    if (state.lastUserDoc) {
      methods.push({
        type: "startAfter",
        value: state.lastUserDoc,
      });
    }

    db.getDocumentByQuery2.call(this, "users", comparisons, methods, function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        // debugger
        commit("addUsers", {
          doc,
        });
      });
      commit("getUsersEnd");
    });
  },
  getInactiveUsers({ commit }, args) {
    if (state.loadingInactiveUsers) {
      return;
    }
    state.loadingInactiveUsers = true;
    setTimeout(() => {
      commit("getInactiveUsersEnd");
    }, 5000);

    this.$db = getGoogleFirestore(); //must do this to pass $db into db call
    console.log("loading inactive users from store");
    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
      {
        field: "disabled",
        comparison: "==",
        value: true,
      },
    ];
    let methods = [
      {
        type: "limit",
        value: 10,
      },
      {
        type: "orderBy",
        value: "'displayName'",
      },
    ];
    if (state.lastInactiveUserDoc) {
      methods.push({
        type: "startAfter",
        value: state.lastInactiveUserDoc,
      });
    }

    db.getDocumentByQuery2.call(this, "users", comparisons, methods, function (querySnapshot) {
      state.loadingInactiveUsers = false;
      querySnapshot.forEach(function (doc) {
        // debugger
        commit("addInactiveUsers", {
          doc,
        });
      });
      commit("getInactiveUsersEnd");
    });
  },
  getGroupUsers({ commit }, args) {
    state.loadingGroupUsers = true;
    setTimeout(() => {
      commit("getGroupUsersEnd");
    }, 5000);

    if (args.clearUsers) {
      commit("cacheAndClearGroupUsers");
    }
    this.$db = getGoogleFirestore(); //must do this to pass $db into db call
    console.log("loading group users from store");
    const group = args.group;
    //when group changes, cache and clear the old one
    if (state.currentGroup && state.currentGroup != group) {
      commit("cacheAndClearGroupUsers");
    }

    //bring back the cache if available
    if (state.groupUserStore[group]) {
      state.groupUsers = state.groupUserStore[group].groupUsers;
      state.lastGroupUserDoc = state.groupUserStore[group].lastGroupUserDoc;
    }

    state.currentGroup = group;

    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
      {
        field: "groups",
        comparison: "array-contains",
        value: group,
      },
    ];
    let methods = [
      {
        type: "limit",
        value: 100,
      },
      {
        type: "orderBy",
        value: "'displayName'",
      },
    ];
    if (state.lastGroupUserDoc) {
      methods.push({
        type: "startAfter",
        value: state.lastGroupUserDoc,
      });
    }
    db.getDocumentByQuery2.call(this, "users", comparisons, methods, function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        // debugger
        commit("addGroupUsers", {
          doc,
          group,
        });
      });
      commit("getGroupUsersEnd");
    });
  },
  getGroupInactiveUsers({ commit }, args) {
    state.loadingGroupInactiveUsers = true;
    setTimeout(() => {
      commit("getGroupInativeUsersEnd");
    }, 5000);

    this.$db = getGoogleFirestore(); //must do this to pass $db into db call
    const group = args.group;
    //when group changes, cache and clear the old one
    if (state.currentInactiveGroup && state.currentInactiveGroup != group) {
      commit("cacheAndClearGroupInactiveUsers");
      //commit("cacheAndClearGroupUsers");
    }

    //bring back the cache if available
    if (state.groupInactiveUserStore[group]) {
      state.groupInactiveUsers = state.groupInactiveUserStore[group].groupInactiveUsers;
      state.lastGroupInactiveUserDoc = state.groupInactiveUserStore[group].lastGroupInactiveUserDoc;
    }
    state.currentInactiveGroup = group;

    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
      {
        field: "groups",
        comparison: "array-contains",
        value: group,
      },
      {
        field: "disabled",
        comparison: "==",
        value: true,
      },
    ];
    let methods = [
      {
        type: "limit",
        value: 10,
      },
      {
        type: "orderBy",
        value: "'displayName'",
      },
    ];
    if (state.lastGroupInactiveUserDoc) {
      methods.push({
        type: "startAfter",
        value: state.lastGroupInactiveUserDoc,
      });
    }
    db.getDocumentByQuery2.call(this, "users", comparisons, methods, function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        commit("addGroupInactiveUsers", {
          doc,
          group,
        });
      });
      commit("getGroupInativeUsersEnd");
    });
  },
  updateUser({ commit }, args) {
    const user = args.user;
    const update = args.update;
    const level = args.level;
    const action = args.action;
    if (action == "add") {
      commit("addNewUser", {
        user,
        level,
      });
    } else if (action == "update" || action == "remove") {
      commit("updateUser", {
        user,
        update,
        action,
      });
    }
  },
  updateInvitation({ commit }, args) {
    const invite = args.invite;
    const level = args.level;
    const action = args.action;
    if (action == "add") {
      commit("addNewInvitation", {
        invite,
        level,
      });
    } else if (action == "remove" || action == "update") {
      commit("updateInvitation", {
        invite,
        action,
      });
    }
  },
  getInvitations({ commit }, args) {
    if (state.loadingInvitations) {
      return;
    }
    state.loadingInvitations = true;
    setTimeout(() => {
      commit("getInvitationsEnd");
    }, 5000);

    this.$db = getGoogleFirestore(); //must do this to pass $db into db call
    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
    ];
    let methods = [
      {
        type: "limit",
        value: 100,
      },
      {
        type: "orderBy",
        value: "'displayName'",
      },
    ];
    if (state.lastInvitationDoc) {
      methods.push({
        type: "startAfter",
        value: state.lastInvitationDoc,
      });
    }

    db.getDocumentByQuery2.call(this, "invitations", comparisons, methods, function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        commit("addInvitation", {
          doc,
        });
      });
      commit("getInvitationsEnd");
    });
  },
  getGroupInvitations({ commit }, args) {
    state.loadingGroupInvitations = true;
    setTimeout(() => {
      commit("getGroupInvitationsEnd");
    }, 5000);

    this.$db = getGoogleFirestore(); //must do this to pass $db into db call
    console.log("loading group invitations from store");

    //when group changes, cache and clear the old one
    if (state.currentInvitationGroup && state.currentInvitationGroup != args.group) {
      commit("cacheAndClearGroupInvitations");
    }

    //bring back the cache if available
    if (state.groupInvitationStore[args.group]) {
      state.groupInvitations = state.groupInvitationStore[args.group].groupInvitations;
      state.lastGroupInvitationDoc = state.groupInvitationStore[args.group].lastGroupInvitationDoc;
    }

    state.currentInvitationGroup = args.group;
    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
      {
        field: "groups",
        comparison: "array-contains",
        value: args.group,
      },
    ];
    let methods = [
      {
        type: "limit",
        value: 100,
      },
      {
        type: "orderBy",
        value: "'displayName'",
      },
    ];
    if (state.lastGroupInvitationDoc) {
      methods.push({
        type: "startAfter",
        value: state.lastGroupInvitationDoc,
      });
    }
    db.getDocumentByQuery2.call(this, "invitations", comparisons, methods, function (querySnapshot) {
      // debugger
      querySnapshot.forEach(function (doc) {
        // debugger
        commit("addGroupInvitation", {
          doc,
        });
      });
      commit("getGroupInvitationsEnd");
    });
  },
  //categories
  getCategories({ dispatch, commit }, args) {
    if (state.categoriesData.length > 0) {
      commit("getCategoriesEnd");
      return;
    }
    state.loadingCategories = true;
    // setTimeout(()=>{
    //   commit("getCategoriesEnd");
    // },5000)

    this.$db = getGoogleFirestore(); //must do this to pass $db into db call
    console.log("loading categories from store");

    const comparisons = [
      {
        field: "organization",
        comparison: "==",
        value: args.organization,
      },
    ];
    let methods = [
      {
        type: "limit",
        value: 100,
      },
    ];
    if (state.lastCatgoryDoc) {
      methods.push({
        type: "startAfter",
        value: state.lastCatgoryDoc,
      });
    }
    db.getDocumentByQuery2.call(this, "categories", comparisons, methods, async function (querySnapshot) {
      const categoriesArray = environment.backendType === "ali" ? querySnapshot : querySnapshot.docs;
      await Promise.all(
        categoriesArray.map(async (doc) => {
          const category = doc.data();
          const items = await dispatch("getSubCategories", { organization: category.organization, category: category });
          commit("addCategories", {
            doc,
            items,
          });
        })
      );
      commit("getCategoriesEnd");
    });
  },
  getSubCategories({ commit }, args) {
    return new Promise(
      function (resolve, reject) {
        this.$db = getGoogleFirestore(); //must do this to pass $db into db call
        //console.log("loading subcategories from store");
        const comparisons = [
          {
            field: "organization",
            comparison: "==",
            value: args.organization,
          },
          {
            field: "category",
            comparison: "==",
            value: args.category.id,
          },
        ];
        let methods = [
          {
            type: "limit",
            value: 100,
          },
        ];

        db.getDocumentByQuery
          .call(this, "subcategories", comparisons, methods)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      }.bind(this)
    );
  },
};

// getters are functions
/** TODO: move user profile state, mutation and action to user module */
const getters = {
  user_profile_list: (state) => state.userProfile,
  loading_workflow: (state) => state.loadingWorkflow,
  getter_workflows: (state) => state.workflows,
  getter_organization: (state) => (state.userProfile[0] ? state.userProfile[0].organization : ""),
  getter_is_gen2: (state) => state.userProfile[0] && state.userProfile[0].organizationInfo.generation === "gen2",
  getter_is_gen2_plus: (state) =>
    state.userProfile[0] && state.userProfile[0].organizationInfo.generation === "gen2plus",
  getter_is_allowed_using_maven_features: (state, getters) => getters.getter_is_gen2_plus && IS_MAVEN_WHITELIST_DOMAIN,
  getter_custom_attributes_with_id_users: (state) =>
    state.users.map((user) => ({ ...user, customAttributes: { id: user.id, ...user.customAttributes } })),
  getter_custom_attributes_with_id_inactive_users: (state) =>
    state.inactiveUsers.map((user) => ({ ...user, customAttributes: { id: user.id, ...user.customAttributes } })),
};

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    annotation,
    editor,
    global,
    idle,
    navigator,
    navigatorSkill,
    builderMain,
    skillNotification,
    workspaceColor,
    category,
    workflowComments,
    workflowPlayer,
    workflowQuiz,
    workflowReactions,
    permission,
    notification,
    textToSpeech,
    mavenChat,
    mavenMap,
    workspaceGroups,
    workspace,
    workspaceStates,
    studio,
    auth,
    stepSentences,
    aiQuizEditor,
    userConsents,
    maven2,
    rusticiProcess,
    skillBuilder,
  },
});
export default store;
