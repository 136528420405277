<template>
  <div class="rustici">
    <div class="rusticiWrapper">
      <d-layout class="integrationTitle align-center">
        <d-flex>
          <p class="integrationTitle__text mb-0">
            {{ $t("admin.lmsExporter") }}
          </p>
        </d-flex>
      </d-layout>

      <div class="integrateWorkflows">
        <p class="integrateWorkflows__title">{{ $t("admin.integrateWorkflowsTitle") }}</p>
        <span class="integrateWorkflows__description"
          >{{ $t("admin.integrateWorkflowsDescription") }}
          <d-tooltip class="integrateWorkflows__toolTip ml-2" :placementDown="isBottomTooltip">
            <template slot="content">
              <div class="integrateWorkflows__toolTipContent py-2 pl-2">
                <p class="mb-3">{{ $t("admin.exportAvailableFormatIndicator") }}</p>
                <ul class="integrateWorkflows__tooltipList">
                  <li v-for="exportType in rusticiExportTypes">- {{ exportType }}</li>
                </ul>
                <p class="mt-3 mb-0">{{ $t("admin.uploadAndDownloadIndicator") }}</p>
              </div>
            </template>
            <font-awesome-icon :icon="['far', 'circle-question']" /></d-tooltip
        ></span>

        <d-button
          @click="showExportWorkflowPopup = true"
          :disabled="selectDisabled"
          round
          depressed
          class="body-2 px-4 integrateWorkflows__button"
          dark
          >{{ $t("admin.selectWorkflows") }}</d-button
        >
      </div>

      <div class="integratedWorkflowsTable">
        <div class="integratedWorkflowsTable__title">
          <p class="integratedWorkflowsTable__headerText">{{ $t("admin.downloadHistory") }}</p>
        </div>
        <div class="integratedWorkflowsTable__dataTableWrapper">
          <d-pagination-table
            class="integratedWorkflowsTable__paginationTable"
            hideSearchInput
            :isLoading="isLoading"
            :headers="tableHeader"
            :rows="processesHistoryList"
            :total-count="getter_total_count"
            :page-size="pageSize"
            :current-page="currentPage + 1"
            :order="order"
            :order-by="orderBy"
            @onPageChanged="handlePageChanged"
            @onPageSizeChanged="handlePageSizeChanged"
            @onSortChange="handleSortChange"
          >
            <template slot="row.statusText" slot-scope="{ row }"
              ><div class="integratedWorkflowsTable__exportStatus">
                <span class="integratedWorkflowsTable__statusLight" :class="row.statusClass"></span
                ><span>{{ row.statusText }}</span>
              </div></template
            >
            <template slot="row.actions" slot-scope="{ row }">
              <button
                v-if="row.hasSeeDetailsAction"
                @click="seeDetails(row.id)"
                class="integratedWorkflowsTable__seeDetails"
              >
                {{ $t("admin.seeDetails") }}
              </button>
              <d-button
                v-if="row.action.text"
                @click="row.action.actionHandler(row)"
                round
                depressed
                light
                outline
                small
                class="integratedWorkflowsTable__actionButton"
                >{{ row.action.text }}</d-button
              ></template
            >
          </d-pagination-table>
        </div>
      </div>
    </div>

    <admin-setting-rustici-integrate-workflow-popup
      v-if="showExportWorkflowPopup"
      @handleExportWorkflowIds="handleExportWorkflowIds"
      @closePopup="showExportWorkflowPopup = false"
    />

    <rustici-exported-workflow-popup
      v-if="showExportedWorkflowPopup"
      @closePopup="showExportedWorkflowPopup = false"
      :isLoading="isLoadingExportedWorkflows"
      :exportedWorkflows="exportedWorkflows"
    />
  </div>
</template>

<script>
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinAnalytics from "@/components/MixinAnalytics.vue";
import DPaginationTable from "@/components/ui_components/DPaginationTable.vue";
import dayjs from "dayjs";
import { fetchWorkflowByWorkspaceId } from "@/server/api-server.js";
import { getExportProcessesById, postCourseExportProcess, retryGetExportProcess } from "@/server/rustici-server.js";
import { loggingError } from "@/server/error-log-server.js";
import { getDateTimeText } from "@/js/time/format.js";
import { downloadFile } from "@/js/common/download.js";
import { rusticiExportTypes } from "@/constants/rusticiExportType.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AdminSettingRustici",
  components: {
    DPaginationTable,
    DTooltip: () => import("@/components/ui_components/DTooltip.vue"),
    AdminSettingRusticiIntegrateWorkflowPopup: () =>
      import("@/components/DAdmin/Settings/integrations/AdminSettingRusticiIntegrateWorkflowPopup.vue"),
    RusticiExportedWorkflowPopup: () =>
      import("@/components/DAdmin/Settings/integrations/Rustici/RusticiExportedWorkflowPopup.vue"),
  },
  mixins: [MixinUser, MixinDB, MixinAnalytics],
  props: {
    isBottomTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rusticiExportTypes,
      exportedWorkflows: [],
      trackRusticiPackageStatusTimer: null,
      currentPage: 0,
      pageSize: 10,
      order: "desc",
      orderBy: "created_at",
      showExportWorkflowPopup: false,
      showExportedWorkflowPopup: false,
      isLoading: false,
      isLoadingExportedWorkflows: false,
      selectDisabled: false,
      tableHeader: [
        {
          key: "createdAt",
          label: this.$t("admin.createdDate"),
          sortable: true,
        },
        {
          key: "createdBy",
          label: this.$t("admin.createdBy"),
        },
        {
          key: "exportType",
          label: this.$t("admin.downloadFileFormat"),
        },
        {
          key: "statusText",
          label: this.$t("admin.status"),
        },
        {
          key: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getter_organization"]),
    ...mapGetters("rusticiProcess", [
      "getter_export_processes_list",
      "getter_total_count",
      "getter_not_ready_export_processes_ids",
    ]),
    processesHistoryList() {
      return this.getter_export_processes_list.map((item) => {
        const { text: statusText, class: statusClass, action } = this.mapProcessesStatusAndAction(item);
        return {
          ...item,
          createdAt: getDateTimeText(item.createdAt),
          createdBy: item.userName,
          exportType: rusticiExportTypes[item.exportType],
          statusText,
          statusClass,
          hasSeeDetailsAction: ["finished", "error"].includes(item.status),
          action,
        };
      });
    },
  },
  async mounted() {
    this.getExportProcessesList();
  },
  beforeDestroy() {
    clearInterval(this.trackRusticiPackageStatusTimer);
  },
  watch: {
    getter_not_ready_export_processes_ids: {
      immediate: true,
      handler(getter_not_ready_export_processes_ids) {
        clearInterval(this.trackRusticiPackageStatusTimer);
        this.trackRusticiPackageStatusTimer = null;
        if (getter_not_ready_export_processes_ids.length > 0) {
          this.trackRusticiPackageStatusTimer = setInterval(() => this.syncExportProcesses(), 5000);
        }
      },
    },
  },
  methods: {
    ...mapActions("global", ["openAlert"]),
    ...mapActions("rusticiProcess", ["fetchExportProcessesList"]),
    async retryFetchExportProcess(exportProcessId) {
      try {
        const { ok, errorMessage } = await retryGetExportProcess({ exportProcessId });
        if (!ok) {
          this.openAlert({
            message: errorMessage,
            type: "error",
          });
          return;
        }
        this.syncExportProcesses();
      } catch (error) {
        console.log(error);
        loggingError(error);
      }
    },
    async syncExportProcesses() {
      try {
        this.handleFetchExportProcesses();
      } catch (error) {
        console.log(error);
        loggingError(error);
      }
    },
    mapProcessesStatusAndAction(item) {
      const statusAndActionsMapping = {
        finished: {
          text: this.$t("admin.created"),
          class: "statusLight--created",
          action:
            item.fileUrl && dayjs().isBefore(dayjs(item.urlExpiredAt))
              ? {
                  text: this.$t("admin.download"),
                  actionHandler: () => downloadFile(item.fileUrl),
                }
              : {},
        },
        processing: {
          text: this.$t("admin.processing"),
          class: "statusLight--processing",
          action: {},
        },
        error: {
          text: this.$t("admin.failed"),
          class: "statusLight--failed",
          action: !item.maxRetriesReached
            ? {
                text: this.$t("admin.retry"),
                actionHandler: () => this.retryFetchExportProcess(item.id),
              }
            : {},
        },
      };
      return statusAndActionsMapping[item.status] || statusAndActionsMapping.processing;
    },
    async getExportProcessesList() {
      try {
        this.isLoading = true;
        await this.handleFetchExportProcesses();
      } catch (error) {
        console.log(error);
        loggingError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleFetchExportProcesses() {
      const params = {
        size: this.pageSize,
        page: this.currentPage,
        ...(this.orderBy && {
          order: this.order === "desc",
          orderBy: this.orderBy === "createdAt" ? "created_at" : "",
        }),
      };
      await this.fetchExportProcessesList(params);
    },
    async seeDetails(exportProcessId) {
      try {
        this.isLoadingExportedWorkflows = true;
        this.exportedWorkflows = [];
        this.showExportedWorkflowPopup = true;
        const { ok, data } = await getExportProcessesById({ exportProcessId });
        if (!ok) {
          this.openAlert({
            message: this.$t("admin.alertGetExportedWorkflowDetailsFailed"),
            type: "error",
          });
          return;
        }
        this.exportedWorkflows = data.item.workflowsInfo;
      } catch (error) {
        console.log(error);
        loggingError(error);
      } finally {
        this.isLoadingExportedWorkflows = false;
      }
    },
    handlePageSizeChanged(newPageSize) {
      this.currentPage = 1;
      this.pageSize = newPageSize;
      this.getExportProcessesList();
    },
    handlePageChanged(newPage) {
      this.currentPage = newPage - 1;
      this.getExportProcessesList();
    },
    handleSortChange({ orderBy, order }) {
      this.currentPage = 1;
      this.orderBy = orderBy;
      this.order = order;
      this.getExportProcessesList();
    },
    async handleExportWorkflowIds(payload) {
      const { idsFromInsertedWorkflows, idsFromSelectedWorkspaces, exportType } = payload;
      try {
        this.selectDisabled = true;
        this.isLoading = true;
        const fetchedWorkflowIds = await this.handleFetchWorkflowByWorkspaceId(idsFromSelectedWorkspaces);
        const workflowIds = [...idsFromInsertedWorkflows, ...fetchedWorkflowIds.flat()];
        await this.createRusticiPackage({ workflowIds, exportType });
        this.getExportProcessesList();
      } catch (error) {
        this.openAlert({
          message: this.$t("admin.alertRusticiBatchExportFailed"),
          type: "error",
        });
        loggingError(error);
      } finally {
        this.isLoading = false;
        this.selectDisabled = false;
      }
    },
    async handleFetchWorkflowByWorkspaceId(workspaceIds) {
      return await Promise.all(
        workspaceIds.map((workspaceId) =>
          /**
           * Use `then` and `catch` to maintain the parallel processing of `Promise.all`.
           */
          fetchWorkflowByWorkspaceId(workspaceId).then(({ ok, data, errorMessage }) => {
            if (!ok) {
              throw new Error(errorMessage);
            }
            return data.item.workflows.filter((workflow) => workflow.published).map((workflow) => workflow.id);
          })
        )
      );
    },
    async createRusticiPackage({ workflowIds, exportType }) {
      const { ok } = await postCourseExportProcess({ workflowIds, exportType });
      if (!ok) {
        this.openAlert({
          message: this.$t("admin.alertRusticiBatchExportFailed"),
          type: "error",
        });
        return;
      }
      this.$emit("closePopup");
    },
  },
};
</script>

<style scoped lang="scss">
.rustici {
  position: relative;
  width: 100%;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;

  .rusticiWrapper {
    height: 100%;
  }

  .integrationTitle {
    color: var(--dLigthGrey600-color);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.18px;
    border: 1px solid #e7e7e7;
    background-color: #ffffff;
    padding: 24px;

    &__text {
      color: #3f3f3f;
      font-size: 20px;
      letter-spacing: 0;
    }
  }

  .integrateWorkflows {
    display: flex;
    flex-direction: column;
    background-color: #fff;

    &__title {
      color: var(--dLigthGrey600-color);
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-top: 55px;
      margin-bottom: -4px;
    }

    &__description {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8d909f;
      font-size: 16px;
      line-height: 24px;
    }

    &__toolTip:hover {
      cursor: pointer;
    }

    &__toolTipContent {
      color: #ffffff;
      font-size: 14px;
      line-height: 130%;
      text-align: left;
    }

    &__tooltipList {
      list-style: none;
      padding-left: 0px;
    }

    &__button {
      margin: 0 auto;
      margin-top: 26px;
      margin-bottom: 45px;
      font-size: 16px;
      text-transform: none !important;

      &.DButton.DButton--disabled:not(.DButton--icon):not(.DButton--outline) {
        background-color: rgba(70, 137, 243, 0.32) !important;
        color: #fff !important;
      }
    }
  }

  .integratedWorkflowsTable {
    border: 1px solid #e7e7e7;
    background-color: #ffffff;
    box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);

    &__headerText {
      color: var(--dLigthGrey400-color);
      font-size: 18px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.18px;
      margin-left: 16px;
      margin-top: 16px;
    }

    &__dataTableWrapper {
      width: 100%;
      overflow: auto;
      border-top: 1px solid #e2e7f0;

      &:hover::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
      }

      .v-table__overflow:hover::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
      }
    }

    &__exportStatus {
      display: flex;
      align-items: center;
    }

    &__statusLight {
      width: 8px;
      height: 8px;
      margin-right: 10px;
      border-radius: 50%;

      &.statusLight--created {
        background-color: #208749;
      }

      &.statusLight--processing {
        background-color: #ffb800;
      }

      &.statusLight--failed {
        background-color: #b22b2d;
      }
    }

    &__seeDetails {
      color: var(--brand-blue);
      text-decoration: underline;
      cursor: pointer;
    }

    &__actionButton {
      padding: 0 12px;
      text-align: center;
      font-size: 12px;
      margin-left: 32px;
    }

    .DPaginationTable {
      margin-top: 0px;
    }
  }
}
</style>
