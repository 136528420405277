<template>
  <div class="DPaginationTable">
    <div class="DPaginationTable__title" v-if="$slots.title">
      <slot name="title" />
    </div>
    <div class="DPaginationTable__tab" v-if="tabNames.length > 0">
      <d-tabs v-model="currentTab" autoWidth :tabNames="tabNames" @input="clickTab" height="56" light />
    </div>
    <div v-if="!hideSearchInput" class="DPaginationTableSearchbar">
      <div class="DPaginationTableSearchbar__leftSide">
        <font-awesome-icon :icon="['far', 'magnifying-glass']" />
        <input
          type="text"
          :value="keyword"
          @input="onKeywordChanged"
          :placeholder="searchPlaceholder || $t('admin.searchForItems')"
          class="serach-input"
        />
      </div>
      <div>
        <slot name="search-right"></slot>
      </div>
    </div>
    <div class="DPaginationTable--scrollable">
      <table>
        <thead>
          <tr>
            <th v-for="(header, index) in headers" :key="index" @click="header.sortable && onSortChange(header.key)">
              <div
                class="DPaginationTable__th"
                :class="{ active: orderBy == header.key, 'not-sortable': !header.sortable }"
              >
                {{ header.label }}
                <font-awesome-icon v-if="header.sortable" :icon="getHeaderSortIcon(header.key)" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading || rows.length === 0">
            <td class="empty" :colspan="headers.length">
              <v-progress-linear v-if="isLoading" class="my-0" indeterminate color="#4689f4" height="2" />
              <div class="DPaginationTable__empty">
                <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" spin />
                {{ isLoading ? $t("player.loading") : $t("$vuetify.dataIterator.noResultsText") }}
              </div>
            </td>
          </tr>
          <template v-else>
            <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
              <td v-for="(header, colIndex) in headers" :key="colIndex">
                <slot :name="`row.${header.key}`" v-bind="{ row }">
                  {{ row[header.key] }}
                </slot>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="DPaginationTableFooter">
      <div class="d-flex align-center">
        <span>{{ $t("$vuetify.dataIterator.rowsPerPageText") }}</span>
        <div class="DPaginationTableFooter__pageSize">
          <select :value="pageSize" @change="onPageSizeChanged">
            <option v-for="pageSizeOption in pageSizeOptions" :key="pageSizeOption" :value="pageSizeOption">
              {{ pageSizeOption }}
            </option>
          </select>
        </div>
      </div>
      <div class="DPaginationTableFooter__prevAndNext">
        <span>{{ $t("$vuetify.dataIterator.pageText", pageTextParams) }}</span>
        <button
          class="DPaginationTableFooter__arrowButton"
          @click="$emit('onPageChanged', currentPage - 1)"
          :disabled="currentPage === 1"
        >
          <font-awesome-icon :icon="['far', 'chevron-left']" />
        </button>
        <button
          class="DPaginationTableFooter__arrowButton"
          @click="$emit('onPageChanged', currentPage + 1)"
          :disabled="currentPage === totalPages"
        >
          <font-awesome-icon :icon="['far', 'chevron-right']" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DPaginationTable",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    hideSearchInput: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    order: {
      type: String,
    },
    orderBy: {
      type: String,
    },
    keyword: {
      type: String,
    },
    tabNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizeOptions: [5, 10, 20, 25],
      currentTab: 0,
    };
  },
  components: {
    DTabs: () => import("@/components/ui_components/DTabs.vue"),
  },
  computed: {
    pageTextParams() {
      const from = 1 + (this.currentPage - 1) * this.pageSize;
      const to = this.currentPage * this.pageSize;
      const totalCount = this.totalCount;
      return {
        0: from,
        1: to <= totalCount ? to : totalCount,
        2: totalCount,
      };
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.pageSize);
    },
  },
  methods: {
    clickTab(tabIndex) {
      this.$emit("onTabChanged", tabIndex);
    },
    getHeaderSortIcon(headerKey) {
      if (this.orderBy !== headerKey) return ["fas", "sort"];
      return this.order === "asc" ? ["fas", "sort-up"] : ["fas", "sort-down"];
    },
    onPageSizeChanged(event) {
      this.$emit("onPageSizeChanged", Number(event.target.value));
    },
    onKeywordChanged(event) {
      this.$emit("update:keyword", event.target.value);
    },
    onSortChange(orderBy) {
      if (this.isLoading) return;
      const order = this.orderBy === orderBy ? (this.order === "asc" ? "desc" : "asc") : "asc";
      this.$emit("onSortChange", { orderBy, order });
    },
  },
};
</script>

<style lang="scss" scoped>
.DPaginationTable {
  --padding-x: 16px;
  width: 100%;
  margin-top: 20px;
  background: white;
  border: 1px solid var(--dLigthGrey-color);
  border-radius: 4px;

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    line-height: 18px;

    td {
      text-align: left;
      color: var(--dLigthGrey600-color);
    }
    th {
      background-color: var(--dLigthGrey100-color);
    }
    th,
    td:not(.empty) {
      padding: 8px var(--padding-x);
      text-align: left;
    }
    tr {
      border-bottom: 1pt solid var(--dLigthGrey-color);
    }
    > tbody > tr:hover {
      background-color: #eff2f78a;
    }
  }
  &__title {
    text-align: left;
    border-bottom: 1px solid var(--dLigthGrey200-color);
    padding: 16px 32px;
    font-size: 24px;
    line-height: 24px;
    color: var(--dLigthGrey700-color);
  }
  &__tab {
    text-align: left;
    padding: 8px 26px 0 26px;
    border-bottom: 1px solid var(--dLigthGrey200-color);
  }
  &__th {
    color: var(--dLigthGrey400-color);
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    word-break: keep-all;
    gap: 4px;
    white-space: nowrap;
    &.active {
      color: var(--dLigthGrey600-color);
    }
    &.not-sortable {
      cursor: default;
    }
  }

  &__empty {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  &--scrollable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.DPaginationTableSearchbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  font-size: 16px;
  padding: 0 var(--padding-x);
  color: var(--dLigthGrey600-color);
  border-bottom: 1px solid var(--dLigthGrey-color);
  &__leftSide {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  input {
    outline: none;
    width: 100%;
  }
  input::placeholder {
    color: var(--dLigthGrey300-color);
  }
}

.DPaginationTableFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  font-size: 12px;
  color: #4e4e4e;
  padding: 0 var(--padding-x);

  &__pageSize {
    border: 1px solid var(--dLigthGrey-color);
    padding: 0 12px;
    border-radius: 24px;
    margin: 0 24px;
    > select {
      appearance: auto;
      width: 40px;
      height: 36px;
      color: var(--dLigthGrey600-color);
      outline: none;
    }
  }

  &__prevAndNext {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 2rem;
  }
  &__arrowButton {
    color: var(--dLigthGrey600-color);
    border: 1px solid var(--dLigthGrey-color);
    padding: 8px 12px;
    border-radius: 50%;
    &:disabled {
      color: var(--dLigthGrey-color);
      cursor: not-allowed;
    }
  }
}
</style>
