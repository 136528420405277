import { render, staticRenderFns } from "./PlayerRustici.vue?vue&type=template&id=13203697&scoped=true&"
import script from "./PlayerRustici.vue?vue&type=script&lang=js&"
export * from "./PlayerRustici.vue?vue&type=script&lang=js&"
import style0 from "./PlayerRustici.vue?vue&type=style&index=0&id=13203697&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13203697",
  null
  
)

export default component.exports